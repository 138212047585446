import * as React from 'react'
import loadable from '@loadable/component'

const FieldSet = loadable(() =>
    import('/src/components/form-components/FieldSet')
)
const FieldSpacer = loadable(() =>
    import('/src/components/form-components/FieldSpacer')
)
const SelectTitle = loadable(() =>
    import('/src/components/form-components/SelectTitle')
)
const TextField = loadable(() =>
    import('/src/components/form-components/TextField')
)

const StepUserDetails = ({ isSubmitting, isSubmitted}) => {
    return (
        <FieldSet>
            <FieldSpacer grow={'stretch'}>
                <SelectTitle
                    label={'Title'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
          
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <TextField
                    label={'First Name'}
                    name={'firstname'}
                    placeholder={'e.g. Katie'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                    required
                
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <TextField
                    label={'Last Name'}
                    name={'lastname'}
                    placeholder={'e.g. Jones'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
        
                />
            </FieldSpacer>
        </FieldSet>
    )
}

export default StepUserDetails
